import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRules() {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/rules/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRule(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/rules/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRule(ctx, ruleData) {
      return new Promise((resolve, reject) => {
        axios
          .put('/user/rules', ruleData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRule(ctx, ruleData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/user/rules/${ruleData.id}`, ruleData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRule(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/user/rules/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
