<template>
  <b-card
    class="mb-2"
    no-body
  >
    <div class="custom-search d-flex justify-content-end p-1">
      <b-form-group
        class="mb-0"
      >
        <b-input-group size="lg">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
        </b-input-group>
      </b-form-group>
      <b-button
        variant="primary"
        class="ml-1"
        @click="handleAdd()"
      >
        <feather-icon
          v-if="!isBusy"
          icon="PlusIcon"
          size="21"
          class="text-body text-primary align-middle mr-1 "
          @click="handleAdd"
        />
        Add New
      </b-button>
    </div>
    <b-table
      responsive
      hover
      striped
      outlined
      primary-key="id"
      :items="items"
      :fields="fields"
      :busy="isBusy"
      :filter="filter"
    >
      <template #cell(actions)="row">
        <BIconPencil
          v-if="!row.detailsShowing"
          class="edit-icon"
          variant="primary"
          scale="1.5"
          @click="row.toggleDetails"
        />
        <BIconCheck
          v-if="row.detailsShowing"
          class="edit-icon"
          variant="success"
          scale="2.5"
          @click="row.toggleDetails"
        />
        <BIconTrash
          class="remove-icon ml-1"
          variant="danger"
          scale="1.5"
          @click="handleDelete(row.item)"
        />
      </template>

      <template #row-details="row">
        <b-card>
          <json-editor-vue
            v-model="row.item"
            v-bind="editorOptions"
          />
          <b-button
            v-if="row.item.isNew"
            size="sm"
            variant="secondary"
            class="mt-1 mr-1"
            @click="rowCancel(row.item);row.toggleDetails; "
          >
            Cancel
          </b-button>
          <b-button
            size="sm"
            variant="primary"
            class="mt-1"
            @click="rowUpdate(row.item);row.toggleDetails; "
          >
            Save Details
          </b-button>
        </b-card>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BIconTrash,
  BIconPencil,
  BIconCheck,
  BButton,
  BCard,
  BTable,
  BFormGroup,
  BInputGroup,
  BFormInput,

} from 'bootstrap-vue'
import store from '@/store'
import JsonEditorVue from 'json-editor-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import { msEpochToDate } from '@core/utils/filter'
import ruleStore from './ruleStore'

export default {
  components: {
    BIconTrash,
    BIconPencil,
    BIconCheck,
    BButton,
    BCard,
    BTable,
    BFormGroup,
    BInputGroup,
    BFormInput,
    JsonEditorVue,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'fault-code-list'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, ruleStore)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const toast = useToast()
    const isBusy = ref(false)
    const items = ref([])
    const perPage = ref(10)
    const currentPage = ref(1)
    const sortBy = ref('description')
    const sortDesc = ref(true)
    const filter = ref('')
    const editorOptions = {
      mode: 'text',
      mainMenuBar: false,
    }
    const fields = [
      {
        key: 'description',
        label: 'Description',
        sortable: true,
        stickyColumn: true,
      },
      {
        key: 'type',
        label: 'Type',
        sortable: true,
      },
      {
        key: 'ruleActive',
        label: 'Active',
        sortable: true,
      },
      {
        key: 'testMode',
        label: 'Test Mode',
        sortable: true,
      },
      {
        key: 'level',
        label: 'Rule Level',
        sortable: true,
      },
      {
        key: 'resultFaultCode',
        label: 'Result Fault Code',
        sortable: true,
      },
      {
        key: 'expiryHours',
        label: 'Expiry Hours',
        sortable: true,
      },
      {
        key: 'recommendation',
        label: 'Recommendation',
        sortable: true,
        stickyColumn: true,
      },
      {
        key: 'customerNotificationFormat',
        label: 'Customer Notification Format',
        sortable: true,
      },
      {
        key: 'conditions',
        label: 'Conditions',
        sortable: true,
      },
      {
        key: 'inclusions',
        label: 'Inclusions',
        sortable: true,
      },
      {
        key: 'exclusions',
        label: 'Exclusions',
        sortable: true,
      },
      {
        key: 'timetable',
        label: 'Timetable',
        sortable: true,
      },
      {
        key: 'updatedBy',
        label: 'Updated By',
        sortable: true,
      },
      {
        key: 'updatedTime',
        label: 'Updated Time',
        sortable: true,
        formatter: value => msEpochToDate(value),
      },
      {
        key: 'actions',
        label: 'Actions',
        class: 'text-center',
      },
    ]

    const getRules = () => {
      isBusy.value = true
      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/fetchRules`)
        .then(response => {
          items.value = response.data
        })
        .catch(error => {
          console.log(error)
          isBusy.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              message: 'Error while fetching rules',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    getRules()

    const handleAdd = () => {
      const item = {
        id: 0,
        description: '',
        type: '',
        ruleActive: false,
        testMode: false,
        autoApprove: false,
        expiryHours: 0,
        recommendation: [],
        customerNotificationFormat: '',
        level: 'asset',
        resultFaultCode: 0,
        conditions: '',
        inclusions: [],
        exclusions: [],
        timetable: {},
        isNew: true,
        _showDetails: true,
      }
      items.value.unshift(item)
    }

    const rowCancel = data => {
      // delete the item if it is found in the items array
      const index = items.value.findIndex(item => item.id === data.id)
      if (index > -1) {
        items.value.splice(index, 1)
      }
    }

    const handleDelete = data => {
      isBusy.value = true
      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/deleteRule`, data.id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              variant: 'success',
              message: 'Rule deleted successfully',
            },
          })
          getRules()
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              message: 'Error while deleting rule',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    const rowUpdate = data => {
      const dataObj = { ...JSON.parse(data) }
      const isNew = dataObj.isNew || false
      isBusy.value = true
      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/${isNew ? 'addRule' : 'updateRule'}`, dataObj)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              variant: 'success',
              message: 'Rule updated successfully',
            },
          })
          getRules()
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              message: 'Error while updating the rule',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    return {
      isBusy,
      items,
      perPage,
      currentPage,
      sortBy,
      sortDesc,
      filter,
      fields,
      handleAdd,
      rowCancel,
      handleDelete,
      rowUpdate,
      getRules,
      editorOptions,
    }
  },
}
</script>
